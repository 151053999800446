const sounds = [
  {
    name: "CH",
    url:
      "https://embed.music.apple.com/gb/playlist/ch-saylists/pl.0b532ab630eb4e4689bfbb47bf4062d3",
    colour: ["#3c7ae2", "#3c146d"],
  },
  {
    name: "D",
    url:
      "https://embed.music.apple.com/gb/playlist/d-saylists/pl.8ec5ec547ad049fa93a0df39891ecc6e",
    colour: ["#82f221", "#0341f6"],
  },
  {
    name: "F",
    url:
      "https://embed.music.apple.com/gb/playlist/f-saylists/pl.7468cdb17aaf45d7bd82f55f9f71f93b",
    colour: ["#f4115c", "#2e8fee"],
  },
  {
    name: "G",
    url:
      "https://embed.music.apple.com/gb/playlist/g-saylists/pl.b71a87217b9d4e3dacf3a099abc89fc6",
    colour: ["#52e933", "#042e27"],
  },
  {
    name: "K",
    url:
      "https://embed.music.apple.com/gb/playlist/k-saylists/pl.cc6f0c90d7e342ccb701260b0bb412e7",
    colour: ["#72f2a6", "#ba39ec"],
  },
  {
    name: "L",
    url:
      "https://embed.music.apple.com/gb/playlist/l-saylists/pl.7e71c83285f54231a4061b42202388b6",
    colour: ["#112e4e", "#73f18a"],
  },
  {
    name: "R",
    url:
      "https://embed.music.apple.com/gb/playlist/r-saylists/pl.e236b082155c4ca09f20a7bef0f09498",
    colour: ["#6de6e0", "#e63bc8"],
  },
  {
    name: "S",
    url:
      "https://embed.music.apple.com/gb/playlist/s-saylists/pl.e5618dc53e504989830bab8ebc963837",
    colour: ["#6ee8e3", "#0a1e6a"],
  },
  // { name: "SH", url: "", colour: ["#9cee4f", "#0e4aef"] },
  {
    name: "T",
    url:
      "https://embed.music.apple.com/gb/playlist/t-saylists/pl.e101e58ad8f543e7adb2799f352c0910",
    colour: ["#d148dc", "#992423"],
  },
  // { name: "TH", url: "", colour: ["#dd3c8b", "#87f54c"] },
  {
    name: "Z",
    url:
      "https://embed.music.apple.com/gb/playlist/z-saylists/pl.8afed2e6f4dd40118ded4c827a465604",
    colour: ["#e8d352", "#417c25"],
  },
];

export default sounds;
