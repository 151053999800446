import { useState } from "react";
import "./Tile.css";
import Tilt from "react-parallax-tilt";
{/* w-3/4 mx-auto my-6 max-w-md md:mx-0 md:ml-10 */}

function Tile(prop: { sound: string; url: string; colour: string[], useTilt: boolean }) {
  const [[flipHorizontally]] = useState([false]);
  return (
    <div className="Tile w-3/4 mx-auto my-6 max-w-md md:mx-0">
      <Tilt
        gyroscope={prop.useTilt}
        tiltEnable={prop.useTilt}
        flipHorizontally={flipHorizontally}
        tiltMaxAngleX={15}
        tiltMaxAngleY={15}
        tiltReverse={prop.useTilt}
      >
        <img
          className="p-4"
          src={require(`../../../public/letters/${prop.sound}.jpg`).default}
          alt={prop.sound}
        />
      </Tilt>
    </div>
  );
}

export default Tile;
