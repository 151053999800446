import "./Nav.css";
import saylists from "./saylists.svg";
import backgroundGradient from "../../images/background-gradient.png";
import video from "./sec1.webm";
import videoMP4 from "./sec1.mp4";

function Nav() {
  return (
    //   <div className="Tile w-3/4 mx-auto my-6 max-w-md flex flex-row items-center">
    <div className="Nav mx-auto pt-12 w-12/12 text-white md:mx-0 md:mx-0 md:ml-0 md:ml-15 z-10 text-center">
      <img className="background-gradient" src={backgroundGradient} alt=""/>
        <video className="bgvideo" autoPlay muted loop playsInline>
            <source src={video} type="video/webm"/>
            <source src={videoMP4} type="video/mp4"/>
        </video>
      <div className="w-11/12 mx-8 mx-auto md:mx-auto my-6 max-w-md mt-4">
        {/* <img className="saylist-icon" src={saylistsHeader} alt=""/> */}
        {/* <img className="mx-auto" src={saylistsHeader} alt="" /> */}
        <img className="mx-auto" src={saylists} alt="" />
      </div>
      <div className="text-3xl pt-12 pb-12 uppercase GraphikSuperItalic">
        <span className="teal">Playlists made to</span> aid speech therapy
      </div>
    </div>
  );
}

export default Nav;
