import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  DotGroup,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import ReactPlayer from "react-player";
import "./Video.css";
import caseStudyVid from "./case_study_v2.mp4";
import arrow from "../../images/arrowDesktop.png";
import lyricMP4 from "./lyric-video.mp4";
import playIconPNG from "./playIcon.png";
import poster from "./poster.jpg";
import { useState } from "react";

function playIcons(){
  return (
    <img width="100px" src={playIconPNG} alt=""/>
  );
}

function Video() {
  const [isPlaying, setisPlaying] = useState(false);
  return (
    <div className="Video max-w-7xl mx-auto pb-12">
      {/* w-full */}
      <CarouselProvider
        naturalSlideWidth={100}
        naturalSlideHeight={55}
        totalSlides={3}
        visibleSlides={1}
        step={1}
        infinite={true}
        className="relative"
        dragEnabled={false}
        isIntrinsicHeight={false}
      >
        <Slider>
          <Slide index={0} className="w-full h-10" onBlur={()=>{setisPlaying(false)}}>
            <div className="player-wrapper" onClick={()=>{setisPlaying(p=>!p)}}>
              <ReactPlayer
                className="react-player"
                width="100%"
                height="98%"
                url={caseStudyVid}
                light={poster}
                playIcon={playIcons()}
                playing={isPlaying}
                playsinline={true}
                controls={true}
              />
            </div>
          </Slide>
          <Slide index={1}>
            <div className="gradient-bg">
              <p className="text-white text-center GraphikBoldItalic">
                We analysed lyrics from over 70 million songs for particular sounds occurring in particular patterns… to find the songs where singing along can help your speech.
              </p>
            </div>
          </Slide>
          <Slide index={2}>
            <div className="player-wrapper">
            <video className="react-player autoPlay" autoPlay muted loop playsInline>
            <source src={lyricMP4} type="video/mp4"/>
              </video>
              {/* <ReactPlayer
                className="react-player"
                width="100%"
                height="100%"
                url={lyricMP4}
                muted={true}
                autoPlay={true}
                loop={true}
              /> */}
            </div>
          </Slide>
        </Slider>
        <ButtonBack className="video-back">
          <img className={isPlaying ? 'disappear' : 'appear'} src={arrow} alt="" />
        </ButtonBack>
        <ButtonNext className="video-next">
          <img className={isPlaying ? 'disappear transform rotate-180' : 'appear transform rotate-180'} src={arrow} alt="" />
        </ButtonNext>
        <DotGroup />
      </CarouselProvider>
    </div>
  );
}

export default Video;
