import React, { useState } from "react";
import "./App.css";
import Tile from "./components/Tile/Tile";
import Nav from "./components/Nav/Nav";
import Lyric from "./components/Lyric/Lyric";
import Video from "./components/Video/Video";
import warnerAppleWhite from "./images/warner-apple-white.png";
import Modal from "react-modal";
import arrow from "./images/arrow.png";
import arrowDesktop from "./images/arrowDesktop.png";
// const { detect } = require('detect-browser');
import * as detect from "detect-browser";
import sounds from "./data";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import {
  BrowserView,
  isMobile,
  isTablet,
  MobileOnlyView,
} from "react-device-detect";

const browser = detect.detect();
let useTilt = true;
let isVertical = isMobile ? true : false;
isVertical = isTablet ? false : true;

if (browser) {
  if (browser.name === "safari" || browser.name === "ios") {
    useTilt = false;
  }
}

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "white",
    width: "80%",
    height: "70%",
    border: "none",
    borderRadius: "15px",
    overflow: "visible",
    zIndex: 20,
  },
  overlay: {
    zIndex: 20,
    background: "rgba(23,23,23,0.7)",
  },
};

const frameStyle = {
  width: "100%",
  height: "100%",
  // overflow: "hidden",
  overflow: "visible",
  background: "transparent",
};

Modal.setAppElement("#modal");

function App() {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [currentSound, setCurrentSound] = useState({
    name: "",
    url: "",
    colour: ["", ""],
  });
  const [currentColour, setCurrentColour] = useState({ background: "" });
  const [tiltEnabled] = useState(useTilt ? true : false);
  const [orientation] = useState(isVertical ? "vertical" : "horizontal");
  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }
  function updateColour(letter) {
    let currentColourString = `linear-gradient(${letter.colour.map((code) => {
      return `${code}`;
    })})`;
    setCurrentColour({ background: `${currentColourString}` });
    customStyles.content = {
      ...customStyles.content,
      ...{ background: currentColourString },
    };
  }
  function nextSaylist(sound) {
    let currentSoundIndex = sounds.findIndex((i, index) => {
      if (i.name === sound.name) {
        return index;
      }
    });
    if (currentSoundIndex === -1) {
      setCurrentSound(sounds[1]);
      updateColour(sounds[1]);
      return sounds[1];
    }
    if (currentSoundIndex + 2 > sounds.length) {
      setCurrentSound(sounds[0]);
      updateColour(sounds[0]);
      return sounds[0];
    } else {
      setCurrentSound(sounds[currentSoundIndex + 1]);
      updateColour(sounds[currentSoundIndex + 1]);
      return sounds[currentSoundIndex + 1];
    }
  }
  function previousSaylist(sound) {
    let currentSoundIndex = sounds.findIndex((i, index) => {
      if (i.name === sound.name) {
        return index;
      }
    });
    if (currentSoundIndex - 1 < 0) {
      setCurrentSound(sounds[sounds.length - 1]);
      updateColour(sounds[sounds.length - 1]);
      return sounds[sounds.length - 1];
    } else {
      setCurrentSound(sounds[currentSoundIndex - 1]);
      updateColour(sounds[currentSoundIndex - 1]);
      return sounds[currentSoundIndex - 1];
    }
  }
  return (
    <div className="App py-10 md:pt-0 GraphikSuperItalic uppercase w-full xl:max-w-full xl:mx-auto relative">
      {/* top-64 */}
      <div className="Nav top-11 z-0 md:w-12/12 md:top-0 md:relative md:rounded-br-2xl md:p-5 xl:w-full">
        {/* <h1 className="hidden xl:block text-white">hello there</h1> */}
        <Nav />
        <img
          className="w-40 ml-12 my-6 absolute right-6 top-0 md:top-0"
          src={warnerAppleWhite}
        />
      </div>
      <Video />
      <div className="slate mx-auto rounded-3xl py-5 my-5 max-w-md relative z-1 md:mx-0 md:ml-0 md:max-w-7xl md:mx-auto md:ml-0 md:w-12/12 md:ml-5">
        <div className="w-4/4 my-6 md:my-12 md:w-12/12">
          <h2 className="text-3xl GraphikSuperItalic text-center text-white uppercase teal-underline w-72 mx-auto">
            Find yours here
          </h2>
        </div>
        <div className="tiles mb-6 w-5/6 mx-auto xl:w-5/6">
          {/* md:grid md:grid-cols-2 */}
          <MobileOnlyView>
            {sounds.map((letter, index) => {
              return (
                <div
                  className="tile-container relative z-10 flex justify-center"
                  key={index}
                  onClick={() => {
                    setCurrentSound(letter);
                    updateColour(letter);
                    openModal();
                  }}
                >
                  <Tile
                    sound={letter.name}
                    url={letter.url}
                    colour={letter.colour}
                    useTilt={tiltEnabled}
                  ></Tile>
                </div>
              );
            })}
          </MobileOnlyView>
          <BrowserView>
            <CarouselProvider
              naturalSlideWidth={100}
              naturalSlideHeight={85}
              totalSlides={10}
              visibleSlides={3}
              step={3}
              infinite={true}
              className="relative saylists-tiles"
              dragEnabled={false}
              orientation={"horizontal"}
            >
              <Slider moveThreshold={0}>
                {sounds.map((letter, index) => {
                  return (
                    <Slide index={index} key={index} className="w-full h-10">
                      <div
                        className="tile-container relative z-10 flex justify-center"
                        key={index}
                        onClick={() => {
                          setCurrentSound(letter);
                          updateColour(letter);
                          openModal();
                        }}
                      >
                        <Tile
                          sound={letter.name}
                          url={letter.url}
                          colour={letter.colour}
                          useTilt={tiltEnabled}
                        ></Tile>
                      </div>
                    </Slide>
                  );
                })}
              </Slider>
              <BrowserView>
                <ButtonBack className="saylist-back">
                  <img src={arrowDesktop} alt="" />
                </ButtonBack>
                <ButtonNext className="saylist-next">
                  <img
                    className="appear transform rotate-180"
                    src={arrowDesktop}
                    alt=""
                  />
                </ButtonNext>
              </BrowserView>
            </CarouselProvider>
          </BrowserView>
          <MobileOnlyView></MobileOnlyView>
        </div>
        <div className="relative">
          <Modal
            closeTimeoutMS={300}
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={customStyles}
          >
            <div className="desktop">
              <div
                className="back"
                onClick={() => {
                  previousSaylist(currentSound);
                }}
              >
                <img src={arrowDesktop} alt="" />
              </div>
              <div
                className="next"
                onClick={() => {
                  nextSaylist(currentSound);
                }}
              >
                <img src={arrowDesktop} alt="" />
              </div>
            </div>
            <div className="mobile">
              <button onClick={closeModal} className="back">
                <img src={arrow} alt="" />
                Back
              </button>
              <button
                onClick={() => {
                  nextSaylist(currentSound);
                }}
                className="next"
              >
                Next Saylist <img src={arrow} alt="" />
              </button>
            </div>
            <button
              onClick={closeModal}
              className="icon absolute -top-10 right-0"
            >
              <svg
                className="w-5 ml-5 mt-1"
                id="Layer_3"
                style={{ fill: "white" }}
                data-name="Layer 3"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 300 300"
              >
                <rect
                  y="99.77"
                  width="300"
                  height="50.23"
                  transform="translate(-44.37 142.64) rotate(-45)"
                />
                <rect
                  y="99.77"
                  width="300"
                  height="50.23"
                  transform="translate(132.24 -69.49) rotate(45)"
                />
              </svg>
            </button>
            <iframe
              allow="autoplay *; encrypted-media *; fullscreen *"
              frameBorder="0"
              height="450"
              style={frameStyle}
              src={currentSound?.url}
            ></iframe>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default App;
